* {
  box-sizing: border-box;
  scroll-behavior: smooth;
}
body {
  margin: 0;
  font-family: 'Inter', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
*::-webkit-scrollbar {
  width: 8px;
}
*::-webkit-scrollbar-thumb {
  background-color: #384159;
  border-radius: 20px;
}
*::-webkit-scrollbar-track
{
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  border-radius: 20px;
  background-color: #F5F5F5;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
#navbar-right-content {
  font-size: 1.25em;
}
.bg-image {
  height: 700px;
  background-image: url('../images/cropped.jpg');
  background-size: cover;
}
.navbar {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}
.nav-link {
  cursor: pointer;
}
.navbar-brand {
  font-size: 1.5rem !important;
}
.navbar-brand img {
  padding-right: 0.75rem;
}
.text-on-panorama h1 {
  opacity: 0;
  transform: translateY(3rem);
  animation: fadeInUp 2s ease forwards;
  text-shadow: 5px 2px 3px rgba(0,0,0,0.3);
}
.service-name {
  border-left: 2px solid #96c9e1;
}
.about-container {
  padding-top: 7em;
  padding-bottom: 7em;
}
.fa, .far, .fas {
  color: #1475a4;
}
.contact-text {
  display: inline-block;
  overflow: hidden;
  vertical-align: middle;
  text-align: start;
}
.contact-row {
  text-align: center;
}
.colored-contact {
  color: #1475a4;
}
#footer-container {
  box-shadow: 0px -4px 3px rgb(231, 231, 231);
  padding: 0.5em 3em;
  margin-top: 3em;
  background-color: #1475a4;
}
#copyright-text {
  user-select: none;
}
.scrollTop {
  position: fixed; 
  width: 100%;
  bottom: 55px;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  cursor: pointer;
  animation: fadeIn 0.3s;
  transition: opacity 0.4s;
  opacity: 0.5;
}
.scrollTop:hover{
  opacity: 1;
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0.5;
  }
}
@keyframes fadeInUp
{
    to
    {
        opacity: 1;
        transform: translateY(0);
    }
}

/*Mobile Screens*/
@media screen and (max-width: 479px) {
  #contact-container, #sponsors-container {
    padding: 5em;
  }
  .about-paragraph {
    margin-bottom: 2em !important;
  }
  .scrollTop {
    bottom: 0;
  }
}
/*Small Mobiles*/
@media screen and (max-width: 375px) {
  
}
/*for tablets*/
@media (min-width:767px) and (max-width:999px) {
}
/*for mini-laptops*/
@media (min-width: 1000px) and (max-width: 1400px) {
}

@media (min-width: 992px) {
  .vertical-line {
    border-left: 1px solid #000000;
    padding-left: 1rem;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 1rem !important;
  }
  .navbar .navbar-nav .nav-link {
    font-size: 1.1em;
  }
  .navbar .navbar-nav .nav-link:hover {
    color: #000000;
  }
  .navbar .navbar-nav .nav-item {
    position: relative;
  }
  .navbar .navbar-nav .nav-item::after {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    background-color: #96c9e1;
    width: 0%;
    content: "";
    height: 2px;
    transition: all 0.5s;
  }
  .navbar .navbar-nav .nav-item:hover::after {
    width: 80%;
  }
  .col-img img{
    height: 45vh;
    object-fit: cover;
  }
  .border-row {
    border-left: 3px solid #96c9e1;
  }
  .about-paragraph {
    width: 50%;
    margin-bottom: 0;
  }
  .about-images {
    padding-top: 5em;
    display: flex;
    width: 100%;
  }
  #contact-container, #sponsors-container {
    padding: 7em;
  }
  .sponsors-row .col-md-3 {
    padding: 1em;
  }

}
/*for standard laptops*/
@media (min-width: 1366px) and (max-width: 1605px) {
}
/*for extra large displays*/
@media (min-width: 1605px) {

}
